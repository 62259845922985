import {
  registerApplication,
  start,
  unloadApplication,
  unregisterApplication,
} from "single-spa";
import { state, axiosIns } from "@app/utility";

const TOKEN_NAME = "dronos-token";
const APP_NAME = "dronos";
const USER_ACCOUNT = "user-account";
const USER_ROLE = "user-role";
const PAID = "paid";
const ADMIN = 1;
const EDITOR = 2;
const VIEWER = 3;
const DRONOS_TOKEN = "dronos-token";
const BEARER = "Bearer ";
const AUTHORIZATION = "Authorization";
const WORKSPACE_ID = "workspaceId";
const SINGLE_SPA_ROUTING_EVENT = "single-spa:routing-event";
const DRONOS_SHARE_COMPONENTS_LOADED = "dronos:share-components:loaded";
const CHANGE_CONFIG_EVENT = "change-config";
const REFRESH_TOKEN_PATH = "/account/auth/refresh-token";

let userToken = getCookieValue(TOKEN_NAME);
let shareComponentsLoaded = false;

const userLoggedIn = Boolean(localStorage.getItem(TOKEN_NAME));
const userAccount = localStorage.getItem(USER_ACCOUNT) || PAID;
const roles = [
  { id: ADMIN, name: "admin" },
  { id: EDITOR, name: "editor" },
  { id: VIEWER, name: "viewer" },
];

const childAppRoutes = [
  "canvas",
  "common",
  "change-password",
  "dashboard",
  "2d-annotation",
  "field-templates",
  "inventory-management",
];
const specialAllowedRoutes = [
  "/canvas/amplitel/digital-twin",
  "/canvas/amplitel/from-above",
  "/canvas/amplitel/los",
  "/canvas/amplitel/side-view",
  "/canvas/amplitel/tower-view",
];
const publicRoutes = [
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
  "/no-workspace",
];
const privateRoutes = [
  "/asset-management",
  "/common",
  "/change-password",
  "/canvas",
  "/dashboard",
  "/2d-annotation",
];

const configNames = {
  canvas: "/canvas",
  common: "/common",
  inventoryManagement: "/inventory-management",
  amplitel: "/canvas/amplitel",
  auth: "/verify-otp",
};

const userRole = [roles[0]];
localStorage.setItem(USER_ROLE, JSON.stringify(userRole));

const props = {
  mainApp: APP_NAME,
  userAuth: userLoggedIn,
};

if (userLoggedIn) {
  validateToken()
} else {
  registerAndStart()
}

function registerAndStart() {
  registerApps(userLoggedIn, props);
  start({});
}

function getCookieValue(cookieName) {
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((cookie) => cookie.trim().startsWith(cookieName));
  return cookie ? cookie.split("=")[1] : null;
}

function registerApps(userLoggedIn, props) {
  const noLoginApps = [
    {
      name: "@app/auth",
      activeWhen: ["/"],
    },
    {
      name: "micro-canvas",
      activeWhen: () =>
        specialAllowedRoutes.includes(location.pathname) ||
        (location.pathname.startsWith("/canvas") &&
          userLoggedIn &&
          userAccount === PAID),
      customProps: () => props,
    }
  ]
  const needLoginApps = [
    {
      name: "@app/auth",
      activeWhen: ["/change-password"],
    },
    {
      name: "@utm-national",
      activeWhen: () =>
        !childAppRoutes.includes(location.pathname.split("/")[1]),
      customProps: () => props,
    },
    { name: "@app/common", activeWhen: ["/common"], customProps: () => props },
    { name: "fe-ng-dashboard-reporting", activeWhen: ["/", "/dashboard"] },
    {
      name: "field-templates",
      activeWhen: ["/field-templates"],
      customProps: () => props,
    },
    {
      name: "hierarchy-structure",
      activeWhen: ["/asset-management"],
      customProps: () => props,
    },
    { name: "fe-inventory-management", activeWhen: ["/inventory-management"] },
    { name: "2d-annotation", activeWhen: ["/2d-annotation"] },
    {
      name: "micro-canvas",
      activeWhen: () =>
        specialAllowedRoutes.includes(location.pathname) ||
        (location.pathname.startsWith("/canvas") &&
          userLoggedIn &&
          userAccount === PAID),
      customProps: () => props,
    },
    { name: "fe-micro-share-components", activeWhen: ["/"] },
    { name: "data-upload", activeWhen: ["/"] },
  ];

  let apps = noLoginApps
  if (userLoggedIn) {
    apps = needLoginApps
  }

  apps.forEach((app) =>
    registerApplication({ name: app.name, app: loadApp(app.name), ...app })
  );
}

function loadApp(app) {
  return () => System.import(app);
}

function checkPermission() {
  const strUserRole = localStorage.getItem(USER_ROLE);
  const checking =
    strUserRole &&
    userRole.find((role) => role.id === ADMIN || role.id === EDITOR);
  return Boolean(checking);
}

function replaceState() {
  window.history.replaceState({}, "", "/");
}

function isPublicRoute(basePath) {
  return publicRoutes.includes(`/${basePath}`);
}

function isPrivateRoute(basePath) {
  return privateRoutes.includes(`/${basePath}`);
}

function isSpecialAllowedRoute(pathName) {
  return specialAllowedRoutes.includes(pathName);
}

function isFreeUserOrNoPermission() {
  return userAccount === "free" || !checkPermission();
}

function getConfigName(pathName) {
  if (
    (pathName == "/" ||
      pathName.startsWith("/verify-otp") ||
      publicRoutes.includes(window.location.pathname)) &&
    !userLoggedIn
  ) {
    return "auth";
  }

  for (const [configName, configPath] of Object.entries(configNames)) {
    if (pathName.startsWith(configPath)) {
      return configName;
    }
  }
  return "";
}

window.addEventListener(SINGLE_SPA_ROUTING_EVENT, ({ detail }) => {
  const pathName = new URL(detail.newUrl).pathname;
  const basePath = pathName.split("/")[1];

  if (userLoggedIn) {
    if (
      isPublicRoute(basePath) ||
      (pathName.startsWith(configNames.canvas) &&
        !isSpecialAllowedRoute(pathName) &&
        isFreeUserOrNoPermission())
    ) {
      replaceState();
    }
  } else if (isPrivateRoute(basePath) && !isSpecialAllowedRoute(pathName)) {
    replaceState();
  }

  listenShareComponentsCallback(pathName, detail);
});

function validateToken() {
  const tokenData = JSON.parse(localStorage.getItem(DRONOS_TOKEN))
  const minutes = getTimeDifference(tokenData.expiry)
  const firstTimeLoad = true
  if (minutes <= 5) {
    refreshToken(firstTimeLoad)
  } else {
    registerAndStart()
    getSetInterval(tokenData)
  }
}

function getSetInterval(tokenData) {
  const getRefreshToken = setInterval(() => {
    const minutes = getTimeDifference(tokenData.expiry)
    if (minutes <= 5) {
      clearInterval(getRefreshToken);
      refreshToken();
    }
  }, 1000);
}

function getTimeDifference(tokenExpiry) {
  const deadline = tokenExpiry + 3300000; // 55 minutes added
  const currentTime = new Date().getTime();
  const difference = deadline - currentTime;
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  return minutes
}

async function refreshToken(firstTimeLoad = false) {
  try {
    const response = await axiosIns.post(REFRESH_TOKEN_PATH);
    const newToken = response.data.token.substring(BEARER.length);
    const currentTime = new Date().getTime();
    const item = {
      value: newToken,
      expiry: currentTime,
    };
    state.setDronosToken(item);

    const authToken = state.getDronosToken()?.value;
    const workspaceId = state.getUserInfo()?.member?.[0]?.workspace?._id;

    axiosIns.defaults.headers[AUTHORIZATION] = `${BEARER}${authToken}`;
    axiosIns.defaults.headers[WORKSPACE_ID] = workspaceId;

    if (firstTimeLoad) {
      registerAndStart()
    }
    if (userLoggedIn) {
      getSetInterval(item);
    }
  } catch (error) {
    localStorage.removeItem(DRONOS_TOKEN);
    window.location.reload();
  }
}

function listenShareComponentsCallback(pathName, detail) {
  const shareComponentsLoadedEvent = new Event(DRONOS_SHARE_COMPONENTS_LOADED);

  window.addEventListener(DRONOS_SHARE_COMPONENTS_LOADED, () => {
    headerSidebarFooterEvent(pathName, detail);
  });

  if (shareComponentsLoaded) {
    headerSidebarFooterEvent(pathName, detail);
  } else {
    window.dispatchEvent(shareComponentsLoadedEvent);
  }
}

function headerSidebarFooterEvent(pathName, detail) {
  const configName = getConfigName(pathName);

  window.addEventListener(
    SINGLE_SPA_ROUTING_EVENT,
    () => {
      // Finish mounting
      const getLocalStorage = localStorage.getItem("config-" + configName);

      const config =
        getLocalStorage !== null ? JSON.parse(getLocalStorage) : null;

      const customEvent = new CustomEvent(CHANGE_CONFIG_EVENT, { detail: config });
      window.dispatchEvent(customEvent);
    },
    { once: true }
  );
}